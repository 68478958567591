import * as React from "react";

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {deepmerge} from "@mui/utils";
import useMediaQuery from "@mui/material/useMediaQuery";
export const ColorModeContext = React.createContext({ toggleColorMode: () => {}, currentColorMode: () => {} });
export default function ToggleColorMode(props) {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const defaultColorMode = 'auto';
    const [mode, setMode] = React.useState(localStorage.getItem('colorMode') ?? defaultColorMode);
    const colorMode = React.useMemo(
        () => ({
            currentColorMode: () => {
                return mode;
            },
            toggleColorMode: () => {
                setMode((prevMode) => {
                    if (prevMode === 'dark') {
                        return 'light';
                    }
                    if (prevMode === 'light') {
                        return 'auto';
                    }
                    return 'dark';

                });
            },
        }),
        [mode],
    );

    const theme = React.useMemo(
        () =>
            createTheme(deepmerge({
                palette: {
                    mode: mode === 'auto' ? (prefersDarkMode ? 'dark': 'light') : mode,
                },
            }, props.theme)),
        [mode,prefersDarkMode],
    );

    React.useEffect(() => {
        localStorage.setItem('colorMode', mode);
    }, [mode]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}
