import * as React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import PropTypes from "prop-types";

function Release() {
    return (
        <>
            Release {RELEASE_VERSION}
        </>
    );
}

const Copyright = (props) => {
    let forwardedProps = {...props};
    delete forwardedProps['showRelease'];

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...forwardedProps}>
            {'Copyright © '}
            <Link color="inherit" href="https://partspc.pl/">
                PartsPC.pl
            </Link>{' '}
            {new Date().getFullYear()}
            {'. '}
            {props.showRelease ? (<Release/>) : null}
        </Typography>
    );
}

Copyright.propTypes = {
    showRelease: PropTypes.bool,
};

Copyright.defaultProps = {
    showRelease: false,
};
export default Copyright;
