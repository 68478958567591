import * as React from "react";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            'pl-PL': {
                translation: {
                    'resources': {
                       'category': {
                           'created': 'Kategoria została stworzona pomyślnie',
                           'updated': 'Kategoria została zaktualizowana pomyślnie',
                           'deleted': 'Kategoria została usunięta pomyślnie',
                           'not_created': 'Wystąpił nieoczekiwany błąd! Kategoria nie została stworzona.',
                           'not_updated': 'Wystąpił nieoczekiwany błąd! Kategoria nie została zaktaulizowana.',
                           'not_deleted': 'Wystąpił nieoczekiwany błąd! Kategoria nie została usunięta.',
                       },
                        'producer': {
                            'created': 'Producent został stworzony pomyślnie',
                            'updated': 'Producent został zaktualizowany pomyślnie',
                            'deleted': 'Producent został usunięty pomyślnie',
                            'not_created': 'Wystąpił nieoczekiwany błąd! Producent nie został stworzony.',
                            'not_updated': 'Wystąpił nieoczekiwany błąd! Producent nie został zaktaulizowany.',
                            'not_deleted': 'Wystąpił nieoczekiwany błąd! Producent nie został usunięty.',
                        }
                    },
                    'order': {
                      'status': {
                          'send': 'Wysłane',
                          'canceled': 'Anulowane',
                          'new': 'Nowe',
                          'waiting_for_shipment': 'Oczekuje na realizację',
                          'finished': 'Zrealizowane',
                      }
                    },
                    'search': {
                        'input': {
                            'placeholder': 'Szukaj...'
                        }
                    },
                    'cart': {
                        'dialogTitle': 'Koszyk',
                        'actions': {
                          'newCart': 'Nowy koszyk',
                          'refreshCart': 'Odśwież',
                          'closeDialog': 'Zamknij',
                        },
                        'heading': {
                            'product': 'Produkt',
                            'ean': 'EAN',
                            'supplier': 'Supplier',
                            'buyAmount': 'Zakup',
                            'sellAmount': 'Sprzedaż',
                            'quantity': 'Ilość',
                            'totalBuyAmount': 'Suma (zakup)',
                            'totalSellAmount': 'Suma (sprzedaż)',
                        }
                    },
                    'products': {
                        'column': {
                            'id': 'ID',
                            'img': 'Zdjęcie',
                            'name': 'Nazwa',
                            'supplier': 'Hurtownia',
                            'ean': 'EAN',
                            'producer': 'Producent',
                            'producerSymbol': 'Symbol producenta',
                            'manufacturerSymbol': 'Symbol producenta',
                            'supplierSymbol': 'Symbol dostawcy',
                            'netPrice': 'Netto',
                            'grossPrice': 'Brutto',
                            'sellPrice': 'Sprzedaży',
                            'manualPrice': 'Manualna',
                            'shopPrice': 'Sklep',
                            'stock': 'Stan',
                            'categories': 'Kategorie',
                        }
                    },
                    'notFound': {
                        'message': 'Taka strona nie istnieje!',
                        'whoops': 'Ups...',
                    },
                    'product': {
                        'attribute': {
                            'category': 'Kategoria',
                            'allegroCategories': 'Kategorie Allegro',
                            'ean': 'EAN',
                            'eanShop': 'EAN (sklep)',
                            'netPrice': 'Cena netto',
                            'manufacturerSymbol': 'Symbol producenta',
                            'supplierSymbol': 'Symbol dostawcy',
                            'grossPrice': 'Cena brutto',
                            'stock': 'Stan',
                            'manualPrice': 'Cena manualna',
                            'shopPrice': 'Cena Sklep',
                            'producer': 'Producent',
                            'producerSymbol': 'Symbol producenta',
                            'changed': 'Zmienione wartości',
                            'shortDescription': 'Opis skrócony',
                        },
                        'attributeChanged': {
                            'ean': 'EAN',
                            'price': 'Cena',
                            'name': 'Nazwa',
                            'description': 'Opis',
                        },
                        'images': {
                            'back': 'Poprzedni',
                            'next': 'Następny'
                        },
                        'tab': {
                            'basic': 'Podstawowe',
                            'images': 'Zdjęcia',
                            'attributes': 'Atrybuty',
                            'descriptions': 'Opisy',
                            'logs': 'Logi',
                        },
                        'action': {
                            'openSkyShop': 'Otwórz SkyShop',
                            'openPrestaShop': 'Otwórz PrestaShop',
                            'openWszystkoPl': 'Otwórz Wszystko.pl',
                            'addToCart': 'Dodaj do koszyka',
                            'updateInventory': 'Zaktualizuj stan i cenę',
                            'sendToWszystkoPl': 'Wyślij do wszystko.pl',
                        },
                        'relatedProducts': {
                            'column': {
                                'id': 'ID',
                                'img': 'Zdjęcie',
                                'name': 'Nazwa',
                                'supplier': 'Hurtownia',
                                'ean': 'EAN',
                                'manufacturerSymbol': 'Symbol producenta',
                                'supplierSymbol': 'Symbol dostawcy',
                                'producer': 'Producent',
                                'producerSymbol': 'Symbol producenta',
                                'netPrice': 'Netto',
                                'grossPrice': 'Brutto',
                                'sellPrice': 'Sprzedaży',
                                'manualPrice': 'Manualna',
                                'shopPrice': 'Sklep',
                                'stock': 'Stan',
                                'category': 'Kategoria',
                            }
                        },
                        'editableImages': {
                            'column': {
                                'id': 'ID',
                                'url': 'Zdjęcie',
                                'sourceUrl': 'Adres źródłowy',
                                'actions': '',
                            },
                            'actions': {
                                'delete': 'Usuń',
                                'add': 'Dodaj',
                            },
                            'form': {
                                'url': {
                                    'label': 'Adres URL zdjęcia',
                                    'placholder': 'Wprowadź adres URL zdjęcia',
                                }
                            }
                        },
                        'description': {
                            'save': 'Zapisz',
                            'edit': 'Edytuj',
                        }
                    },
                }
            }
        },
        lng: "pl-PL",
        fallbackLng: "pl-PL",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n
